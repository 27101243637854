// @flow
"use strict";

import { LOGIN_SIGNATURE_PREFIX }                                                             from "@flexidao/spring-types";
import * as ethers                                                                            from "ethers";
import Web3                                                                                   from "web3";
import { apiUrl, LOGIN, LOGIN_TOKEN }                                                         from "./constants";
import { bytesToHex, concatenateUint8Arrays, getRandomBytes, hexEncode, hexToBytes, signMsg } from "./sign";
import { LoginPayload, LoginResponsePayload, PreLoginResponse, }                              from "./types";
import { checkStatus, extractJson }                                                           from "./utils";

export const init = (web3: any) => {
    if (document.body) {
        document.body.addEventListener('click', (e: Event) => {
            const target: any = e.target;
            const octoVault = window.octoVault;
            const metamaskFound = window.metamaskFound;

            if (target.classList && target.classList.contains("fd-login")) {
                if (octoVault) {
                    loginOctoVault(octoVault);
                } else if (metamaskFound) {
                    login(web3);
                } else {
                    alert("Metamask not found and OctoVault closed.\n\nTry opening OctoVault.")
                }
            }
        });
    }
};


export const login = (web3: Web3) => {
    doLogin(fullTokenHash => {
        return new Promise<string>((resolve, reject) => {
            web3.eth.getAccounts(function (err, accounts) {
                if (err) {
                    console.error(err);
                    reject('Failed to get metamask acounts. error = ' + err);
                }

                if (!accounts.length) {
                    console.error('Wallet is gone. Maybe it needs reopening?');
                    reject('Wallet is gone. Maybe it needs reopening?');
                }

                const from: string = accounts[0];
                return signMsg(web3,
                    "0x" + hexEncode("The following random chars are used to provide full security. Not even the NSA can hijack this:\n\n" + fullTokenHash),
                    from,
                ).then(signature => {
                    resolve(signature);
                })
            });
        })
    });
};

export const loginOctoVault = (octoVault: any) => {
    doLogin(fullTokenHash => {
        return new Promise<string>((resolve, reject) => {
            const msg = LOGIN_SIGNATURE_PREFIX + fullTokenHash;
            octoVault.sign(
                msg
                , resolve
                , reject
            )
        });
    })
};



const doLogin = (signerFn: (fullTokenHash: string) => Promise<string>) => {
    const randomBytes: Uint8Array = getRandomBytes(20);
    let csrfToken: string;

    fetch(apiUrl(LOGIN_TOKEN), {
        mode: 'same-origin',
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(extractJson)
        .then(jsonData => {
            const data: PreLoginResponse | undefined = jsonData;
            if (data) {
                if (!data.authToken
                    || data.authToken.length !== 40) {
                    return Promise.reject(new Error("Token length is wrong"));
                }
                csrfToken = data.csrfToken;
                // console.log(data.authToken, bytesToHex(randomBytes));
                const tokenBytes: Uint8Array = hexToBytes(data.authToken);
                const fullToken: Uint8Array = concatenateUint8Arrays(tokenBytes, randomBytes);
                const fullTokenHex: string = bytesToHex(fullToken);
                const fullTokenHash: string = ethers.utils.keccak256("0x" + fullTokenHex);

                // console.log("fullTokenHex = ", fullTokenHex);
                // console.log("fullTokenHash = ", fullTokenHash);
                return Promise.resolve(fullTokenHash);
            }
            return Promise.reject(new Error("No data form server"));
        })
        .then(signerFn)
        .then(signature => {
            // console.log("signature = ", signature);
            if (!signature) {
                return Promise.reject("Attempting to login without signature")
            }
            const randomBytesToken: string = bytesToHex(randomBytes);
            const payload: LoginPayload = {
                randomBytesToken,
                signature,
            };
            const data: FormData = new FormData();
            data.append("json", JSON.stringify(payload));
            // data.append("_csrf", csrfToken);

            return fetch(apiUrl(LOGIN), {
                mode: 'same-origin',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken,
                },
                credentials: 'same-origin',
                method: 'post',
                body: JSON.stringify(payload)
            })
                .then(res => res.json())
                .then((res: LoginResponsePayload) => {
                    const errorMsg: string | undefined = res && res.error;
                    if (errorMsg) {
                        alert(errorMsg);
                        console.error(errorMsg);
                    } else {
                        location.href = res.redirectTo || "/logout";
                    }
                })
                .catch(err => {
                    const errorMsg: string | undefined = err && err.error;
                    if (errorMsg) {
                        alert(errorMsg);
                        console.error(errorMsg);
                    }
                });
        })
        .catch(function (err) {
            alert(err);
            console.error('Fetch Error :-S', err);
        });
};
