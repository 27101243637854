// @flow
"use strict";

export const API_URL: string = "";

export const LOGIN_TOKEN: "/login-token" = "/login-token";
export const LOGIN: "/login" = "/login";
export const REQUEST_LINK_ACCOUNT: "/request-link-account" = "/request-link-account";

export type ApiUrl = typeof LOGIN | typeof LOGIN_TOKEN | typeof REQUEST_LINK_ACCOUNT;

export const apiUrl = (url: ApiUrl): string => {
  return API_URL + url
};

// @ts-ignore
export const MAX_INT: number = Number.MAX_SAFE_INTEGER || 9007199254740991;
