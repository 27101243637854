// @flow
"use strict";

import {init} from "./login-utils";

var Raven : any;

let alreadyStarted: boolean = false;

const start = () => {
  if (alreadyStarted) {
    return;
  }

  Raven && Raven.config('https://e3ee312cd39f4762b3e6a5315f987bf7@sentry.io/1195895').install();
  window.onerror = function (msg, url, lineNo, columnNo, err) {
    alert([msg, url, lineNo, columnNo, err].join("\n"));
    Raven && Raven.captureException(err, function (err: any, eventId: any) {
      console.log('Reported error ' + eventId);
    });

    return false; // The exception is handled, not reported to the user.
  };


  alreadyStarted = true;
  // @ts-ignore
  const browserWeb3 = window.web3;
// Checking if Web3 has been injected by the browser (Mist/MetaMask)
  if (browserWeb3) {
    // @ts-ignore
    window.metamaskFound = true;
    console.log('Metamask found!');

    // Use the browser's ethereum provider
    // const provider = browserWeb3.currentProvider;
    // const actualWeb3: Web3 = new Web3(provider);

  } else {
    // @ts-ignore
    window.metamaskFound = false;
    // location.href = '/login-bypass'
  }

  // init(actualWeb3);
  init(browserWeb3);
};

// start();
window.addEventListener("load", start);
