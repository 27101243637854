export const checkStatus = (response: Response): Promise<Response> => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(new Error(response.statusText))
    }
};

export const extractJson = (response: Response): Promise<any | undefined> => {
    return response.json()
};
