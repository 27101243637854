// @flow
"use strict";


// import {SigUtil} from "./named_imports";
type Web3 = any;
var Web3 : Web3;

export type TypedData = Array<{
  // These are solidity types. Add more as needed.
  type: "bool" | "address" | "balance" | "transfer"
    | "string" | "bytes" | "bytes20" | "bytes32" | "bytes40"
    | "int" | "uint" | "int8" | "uint8" | "int16" | "uint16" | "int32" | "uint32",
  name: string,
  value: any,
}>


// @ts-ignore
const crypto = (self.crypto || self.msCrypto), QUOTA = 65536;


export const getRandomBytes = (n: number) => {
  const a = new Uint8Array(n);
  for (let i = 0; i < n; i += QUOTA) {
    crypto.getRandomValues(a.subarray(i, i + Math.min(n - i, QUOTA)));
  }
  return a;
};


export const concatenateUint8Arrays = <T extends Uint8Array>(...arrays: Array<Uint8Array>): Uint8Array => {
  let totalLength = 0;
  for (let arr of arrays) {
    totalLength += arr.length;
  }
  let result = new Uint8Array(totalLength);
  let offset = 0;
  for (let arr of arrays) {
    result.set(arr, offset);
    offset += arr.length;
  }
  return result;
};

// Convert a hex string to a byte array
export const hexToBytes = (hex: string): Uint8Array => {
  hex = hex.replace(/^0x/, "");
  const bytes = new Uint8Array(Math.floor(hex.length / 2));
  for (let c = 0; c < hex.length; c += 2)
    bytes[c / 2] = parseInt(hex.substr(c, 2), 16);
  return bytes;
};

// Convert a byte array to a hex string
export const bytesToHex = (bytes: Uint8Array): string => {
  let hex: Array<string> = [];
  for (let i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16));
    hex.push((bytes[i] & 0xF).toString(16));
  }
  return hex.join("");
};

export const signMsg = (web3: Web3, message: string, from: string): Promise<string> => {
    //console.log("from =", from);
  return new Promise((resolve, reject) => {
    web3.personal.sign(message, from, "" , function (err: any, result: any) {
      //console.log("result = ", result);
      if (err) {
        console.error(err);
        reject('Failed to sign. error = ' + err);
      }
      resolve(result);
    });
  });
};


export const hexEncode = function (str: string) {
    var hex, i;

    var result = "";
    for (i = 0; i < str.length; i++) {
        hex = str.charCodeAt(i).toString(16);
        result += ("0" + hex).slice(-2);
    }

    return result
}

export const hexDecode = function (hex: string) {
    var j;
    var hexes = hex.match(/.{1,4}/g) || [];
    var back = "";
    for (j = 0; j < hexes.length; j++) {
        back += String.fromCharCode(parseInt(hexes[j], 16));
    }

    return back;
}
